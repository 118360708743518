<template>
  <div class="content-box">
    <div class="search-bar">
      <search
        @inputChange="searchInput"
        @btnSubmit="searchSubmit"
        :showBtn="true"
        :external="search"
        :label="$t('voucher.search')"
      />
    </div>

    <div v-if="!notActivated" class="voucher-form-container">
      <b-form @submit.prevent="onSubmit" v-if="voucher">
        <h4>{{ $t("voucher.data") }}</h4>
        <b-form-group
          :class="{ row }"
          :label="$t('voucher.code')"
          class="form-group"
        >
          <b-form-input v-model="voucher.code" disabled />
        </b-form-group>

        <b-form-group
          v-if="voucher.status.id === 'ACTIVATED'"
          :class="{ row, error: v$.password.$errors.length }"
          :label="$t('voucher.password')"
          class="form-group"
        >
          <b-form-input v-model="password" id="h-name" />
          <div
            class="input-errors"
            v-for="error of v$.password.$errors"
            :key="error.$uid"
          >
            <small class="text-danger">{{ error.$message }}</small>
          </div>
        </b-form-group>

        <b-form-group
          :class="{ row }"
          :label="$t('voucher.status')"
          class="form-group"
        >
          <b-form-input v-model="voucher.status.label" disabled />
        </b-form-group>

        <b-form-group
          :class="{ row }"
          :label="$t('voucher.activationDate')"
          class="form-group"
        >
          <b-form-input v-model="activated" disabled />
        </b-form-group>


        <b-form-group
          v-if="voucher.usedAt"
          :class="{ row }"
          :label="$t('voucher.applicationDate')"
          class="form-group"
        >
          <b-form-input v-model="used" disabled/>
        </b-form-group>

        <b-form-group
          v-if="userData.role !== 'ROLE_ADMIN'"
          :class="{ row }"
          :label="$t('voucher.validityDate')"
          class="form-group"
        >
          <b-form-input v-model="validity" disabled />
        </b-form-group>

        <b-card v-if="userData.role === 'ROLE_ADMIN'">
          <b-form-group
            :class="{ row }"
            :label="$t('voucher.validityDate')"
            class="form-group"
          >
            <datepicker
              v-model="voucher.validUntil"
              :enableTimePicker="false"
              class="form-input"
              format="dd. MM. yyyy"
              previewFormat="dd. MM. yyyy"
            ></datepicker>
          </b-form-group>

          <b-form-group
            :class="{ row }"
            :label="$t('voucher.supplier')"
            class="form-group"
          >
            <v-select
              v-model="selectedPartner"
              :options="partners"
              :clearable="false"
            />
          </b-form-group>

          <b-form-group
            :class="{ row }"
            :label="$t('voucher.purchasePrice')"
            class="form-group with-button"
          >
            <b-form-input
              type="number"
              min="0"
              step="0.001"
              v-model="purchasePrice"
            />
          </b-form-group>

          <b-form-group
            :label="$t('forms.dedication')"
            label-for="dedication"
          >
            <b-form-textarea
              v-model="voucher.dedication"
              name="dedication"
            />
          </b-form-group>

          <b-button @click="updateVoucher" variant="gray">{{
              $t("voucher.update")
            }}</b-button>
        </b-card>

        <div v-if="voucher.passengers && voucher.passengers.length">
            <div
                v-for="(passenger, index) of voucher.passengers"
                :key="index"
            >
                <h4>{{ $t("voucher.userData") }} #{{ index + 1 }}</h4>
                <b-form-group
                    :class="{ row }"
                    :label="$t('user.name')"
                    class="form-group"
                >
                    <b-form-input v-model="passenger.givenName" disabled />
                </b-form-group>

                <b-form-group
                    :class="{ row }"
                    :label="$t('user.familyName')"
                    class="form-group"
                >
                    <b-form-input v-model="passenger.familyName" disabled />
                </b-form-group>

                <b-form-group
                    :class="{ row }"
                    :label="$t('user.email')"
                    class="form-group"
                >
                    <b-form-input v-model="passenger.email" disabled />
                </b-form-group>

                <b-form-group
                    :class="{ row }"
                    :label="$t('user.telephone')"
                    class="form-group"
                >
                    <b-form-input v-model="passenger.telephone" disabled />
                </b-form-group>
            </div>
        </div>

        <b-form-checkbox
          v-if="
            voucher.status.id === 'ACTIVATED' &&
            userData.role === 'ROLE_PARTNER'
          "
          v-model="showProblemForm"
        >
          {{ $t("voucher.problemReport") }}
        </b-form-checkbox>

        <b-collapse
          v-if="
            voucher.status.id === 'ACTIVATED' &&
            userData.role === 'ROLE_PARTNER'
          "
          v-model="showProblemForm"
        >
          <b-form
            class="voucher-problem-form"
            @submit.prevent="onSubmitMessage"
          >
            <b-form-group
              :class="{ row, error: v$.problem.$errors.length }"
              label=""
              class="form-group"
            >
              <b-form-textarea v-model="problem" />
              <div
                class="input-errors"
                v-for="error of v$.problem.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>

            <b-button type="submit" variant="gray">{{
                $t("forms.send")
              }}</b-button>
          </b-form>
        </b-collapse>

        <b-alert :show="showErr" variant="warning" class="mt-3">
          {{ $t("forms.problem") }}
        </b-alert>

        <b-alert :show="showThanks" variant="success" class="mt-3">
          {{ $t("forms.thanks") }}
        </b-alert>

        <div class="form-actions">
          <b-button variant="gray" @click="$router.push('/vouchers')">{{
              $t("forms.cancel")
            }}</b-button>
          <b-button
            v-if="voucher.status.id === 'ACTIVATED'"
            type="submit"
            variant="primary"
            class="ms-3"
          >{{ $t("voucher.redeem") }}</b-button
          >
        </div>

        <div
          v-if="voucher.id && userData.role === 'ROLE_ADMIN'"
          class="form-actions"
        >
          <b-button @click="genereateVoucher(true)" variant="primary">{{
              $t("voucher.generate")
            }}</b-button>
          <b-button @click="genereateVoucher(false)" variant="primary">{{
              $t("voucher.generateNoBG")
            }}</b-button>
        </div>

        <div class="form-actions">
          <b-button
            v-if="voucher.id && userData.role === 'ROLE_ADMIN'"
            @click="deleteVoucher(true)"
            variant="secondary"
          >{{ $t("voucher.redelete") }}</b-button
          >
        </div>
      </b-form>
      <div v-if="voucher.productVariant" class="product-item">
        <div class="product-item-image">
          <img v-if="voucher.productVariant.product.fileUrl" :src="voucher.productVariant.product.fileUrl" alt="" />
        </div>
        <div class="product-item-info">
          <h4>{{ voucher.productVariant.name }}</h4>
          <table
              v-if="userData.role === 'ROLE_PARTNER'"
          >
              <tr
                  v-for="(conf, index) of orderProductConfigurationsToView(orderItem.productConfigurations)"
                  :key="index"
              >
                  <td colspan="2"><strong>● {{ conf.name }}</strong></td>
              </tr>
              <tr v-for="(key, index) in orderItem.customParameters" :key="index">
                  <td>{{ index }}:</td>
                  <td>{{ key }}</td>
              </tr>
          </table>
          <table
              v-else
          >
            <tr
              v-for="(conf, index) of orderProductConfigurationsToView(orderItem.productConfigurations)"
              :key="index"
            >
              <td><strong>{{ conf.name }}:</strong></td>
              <td><strong>+{{ $helper.priceFormat(conf.priceWithTax, 2) }}</strong></td>
            </tr>
            <tr v-for="(key, index) in orderItem.customParameters" :key="index">
              <td>{{ index }}:</td>
              <td>{{ key }}</td>
            </tr>
          </table>
        </div>
      </div>
      <b-alert variant="warning" :show="!voucher" class="mb-0 w-100">
        {{ $t("voucher.notFound") }}
      </b-alert>
    </div>
    <div v-else class="voucher-form-container">
      <b-alert show variant="warning" class="mb-0 w-100">
        {{ $t("voucher.notActivated", { code: this.voucher.code }) }}
      </b-alert>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";
import Search from "@/components/Inputs/Search.vue";
import Toast from "@/components/Toast.vue";
import { useToast } from "vue-toastification";
import Datepicker from "@vuepic/vue-datepicker";
import vSelect from "vue-select";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  components: {
    Search,
    Datepicker,
    vSelect,
  },
  props: {
    voucherObject: { Object, required: true },
    action: { type: String, default: null },
  },
  mixins: [ListUtils, ResourceUtils],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      password: {
        required: this.$translateError("required", "voucherPassword"),
      },
      problem: { required: this.$translateError("required") },
    };
  },
  async created() {
    if (
      this.voucher.status.id === "WAITING" &&
      this.userData.role === "ROLE_PARTNER"
    ) {
      this.notActivated = true;
      return;
    }

    this.getEnumList("coupon_type", this.types);
    if (this.$route.query.pin) {
      this.password = this.$route.query.pin;
    }

    await this.getUserList({ params: { role: "ROLE_PARTNER" } }, this.partners);
    if (this.voucher.partner) {
      this.selectedPartner = this.partners.find(
        (partner) => partner.id === this.voucher.partner
      );
    }
  },
  computed: {
    activated() {
      return this.$helper.formatDate(this.voucher.activationDate);
    },
    used() {
      return this.$helper.formatDate(this.voucher.usedAt);
    },
    validity() {
      return this.$helper.formatDate(this.voucher.validUntil);
    },
    orderItem() {
      if (
        this.voucher &&
        this.voucher.order &&
        this.voucher.order.items &&
        this.voucher.order.items.length
      ) {
        return this.voucher.order.items.find(
          (item) => item.productVariant === this.voucher.productVariant['@id']
        );
      }
      return null;
    }
  },
  mounted() {
    this.setPurchasePrice()
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      voucher: this.voucherObject,
      search: this.voucherObject.code,
      types: [],
      partners: [],
      purchasePrice: null,
      selectedPartner: null,
      showProblemForm: false,
      problem: "",
      password: "",
      showThanks: false,
      showErr: false,
      notActivated: false,
      productConfigurationsIds: [2,7,8,10,11]
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  methods: {
    orderProductConfigurationsToView(configurations) {
        return configurations.filter(cf => process.env.VUE_APP_VOUCHER_PRODUCT_CONFIGURATION_IDS_TO_VIEW.split(',').includes(cf.productConfiguration.split('/')[2]))
    },
    setPurchasePrice() {
      if (
        this.voucher &&
        this.voucher.order &&
        this.voucher.order.items &&
        this.voucher.order.items.length
      ) {
        this.purchasePrice = this.voucher.order.items.find(
          (item) => item.productVariant === this.voucher.productVariant['@id']
        ).purchasePrice;
      }
    },
    searchSubmit(value) {
      this.search = value;
      this.searchVoucher();
    },
    searchVoucher() {
      this.$Vouchers
        .getCollection({
          params: {
            code: this.search.replace(/[^A-Za-z0-9]/g, ""),
            "order[order.createdAt]": "desc",
          },
        }, "voucher_detail")
        .then((response) => {
          if (
            response.data["hydra:member"] &&
            response.data["hydra:member"].length
          ) {
            this.$router.push(
              `${response.data["hydra:member"][0]["@id"]}/edit`
            );
            this.voucher = response.data["hydra:member"][0];
            this.voucher.status = {
              label: this.$helper.getEnumTranslation(
                "voucher_status",
                this.voucher.status,
                this.$i18n.locale
              ),
              id: this.voucher.status,
            };
          } else {
            this.voucher = null;
          }
        });
    },
    genereateVoucher(withBg) {
      const name = withBg ? this.voucher.code : this.voucher.code + "-nobg";
      const body = {
        name,
        resource: this.voucher["@id"],
        templateName: "VOUCHER",
      };
      if (!withBg) {
        body.templateName = "VOUCHER_WITHOUT_BG";
      }
      this.$Pdfs
        .getResourceByUrl({ url: `/pdfs/${name}` })
        .then((response) => {
          if (response.status === 200) {
            const link = document.createElement("a");
            link.href = this.$helper.getUrlPreventBrowserCache(response.data.notCachedUrl);
            link.target = "_blank";
            link.click();
          }
        })
        .catch(() => {
          this.$Pdfs.createResource({ body }).then((response) => {
            if (response.status === 201) {
              const link = document.createElement("a");
              link.href = this.$helper.getUrlPreventBrowserCache(response.data.notCachedUrl);
              link.target = "_blank";
              link.click();
            }
          });
        });
    },
    deleteVoucher() {
      this.deleteByUrl(
        this.$Pdfs,
        `/pdfs/${this.voucher.code}`,
        this.$t("voucher.pdfBgDeleted")
      );
      this.deleteByUrl(
        this.$Pdfs,
        `/pdfs/${this.voucher.code}-nobg`,
        this.$t("voucher.pdfNoBgDeleted")
      );
    },
    async onSubmit() {
      this.v$.password.$touch();
      if (!this.v$.password.$invalid) {
        const body = {
          pin: this.password,
          code: this.voucher.code.replace(/[^A-Za-z0-9]/g, ""),
        };
        this.$Vouchers
          .createResourceByUrl({ url: "/vouchers/redeem", body })
          .then((response) => {
            if (response.status === 200) {
              this.$router.push(`/vouchers`);
              this.$helper.showToast(
                useToast(),
                Toast,
                this.$t("voucher.redeemed"),
                null,
                "success"
              );
            }
          })
          .catch((error) => {
            if (error.response) {
              this.$emit("clearAction");
              if (error.response.status === 403) {
                this.$helper.showToast(
                  useToast(),
                  Toast,
                  this.$t("forms.incorrectPassword"),
                  this.$t("voucher.incorrectPassword"),
                  "danger"
                );
              } else {
                this.$helper.showToast(
                  useToast(),
                  Toast,
                  error.response.data["hydra:title"],
                  error.response.data["hydra:description"],
                  "danger"
                );
              }
            }
          });
      } else {
        this.$emit("clearAction");
      }
    },
    async onSubmitMessage() {
      this.showThanks = false;
      this.showErr = false;
      this.v$.problem.$touch();
      if (!this.v$.problem.$invalid) {
        const message = `
            <h2>${this.$t("voucher.problem")} - ${this.voucher.code}</h2>
            ${this.$t("partner.singular")}: ${this.userData.givenName} ${
          this.userData.familyName
        }<br>
            ${this.$t("partner.email")}: ${this.userData.email}<br>
            ${this.$t("voucher.singular")}: ${this.voucher.code}<br>
            ${this.$t("email.message")}:<br>
             ${this.problem}
            `;
        const body = {
          templateName: "COMMON_EMAIL",
          placeholders: [
            {
              value: `${this.$t("voucher.problem")} - ${this.voucher.code}`,
              name: "{subject}",
            },
            {
              value: message,
              name: "{body}",
            },
          ],
        };
        this.$Emails
          .createResource({ body })
          .then((response) => {
            if (response.status === 201) {
              this.showThanks = true;
              this.problem = "";
              this.showProblemForm = false;
            }
          })
          .catch(() => {
            this.showErr = true;
          });
      }
    },
    updateVoucher() {
      const body = {
        validUntil: moment(this.voucher.validUntil).format(),
        dedication: this.voucher.dedication
      };
      if(this.selectedPartner) {
        body.partner = this.selectedPartner.id
      }
      if(this.purchasePrice && this.purchasePrice > 0) {
        body.purchasePrice = this.purchasePrice.toString()
      }
      this.$Vouchers
        .updateResourceByUrl({ url: this.voucher["@id"], body })
        .then((response) => {
          if (response.status === 200) {
            this.$helper.showToast(
              useToast(),
              Toast,
              this.$t("voucher.updated"),
              null,
              "success"
            );
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$emit("clearAction");
            this.$helper.showToast(
              useToast(),
              Toast,
              error.response.data["hydra:title"],
              error.response.data["hydra:description"],
              "danger"
            );
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
