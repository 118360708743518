<template>
  <div class="partner-custom-form edit-voucher">
    <vouchers-form
      v-if="voucher"
      :voucherObject="voucher"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import VouchersForm from "./components/VoucherForm.vue";

export default {
  components: {
    VouchersForm,
  },
  data() {
    return {
      voucher: null,
      action: null,
    };
  },
  created() {
    this.loadVoucher();
  },
  methods: {
    loadVoucher() {
      const id = this.$route.params.id;
      this.$Vouchers.getResource({ id }, 'voucher_detail').then((response) => {
        this.voucher = response.data;

        const voucherItem = this.voucher.order.items.find(item => item.productVariant === this.voucher.productVariant['@id'])
        this.voucher.purchasePrice = voucherItem.purchasePrice

        this.voucher.status = {
          label: this.$helper.getEnumTranslation(
            "voucher_status",
            this.voucher.status,
            this.$i18n.locale
          ),
          id: this.voucher.status,
        };
      })
    },
    submitPressed() {
      this.action = "update";
    },
  },
};
</script>
